<template>
  <div>
    <Filters
      type="card"
      :filter-model="filterModel"
      @init="filterChange"
      @on-apply="onApplyFilters"
    />
    <AgentsTableV1
      v-if="report"
      :report="report"
      :interval="interval"
      :services="services"
    />
  </div>
</template>

<script>
import konecta from '@/services/konecta'

export default {
  components: {
    AgentsTableV1: () => import('../charts/agents_table_v1/AgentsTableV1.vue'),
    Filters: () => import('../filters/Filters.vue')
  },
  data() {
    return {
      report: null,
      reports: [],
      filterModel: [],
      services: [],
      interval: {}
    }
  },
  methods: {
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'botNoVersion':
          this.services = filter.value
          this.$router.push({
            query: { services: this.services }
          })
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          }
          break
        default:
          break
      }

      // await this.getLastUpdate()
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(23, 59, 59, 999))
      }
    },
    getReport() {
      this.$vs.loading()
      konecta
        .get(
          '/report/5e7e2ce79b9725b2b6834d6d?select[name]=true&select[description]=true&select[reportType]=true&select[_extra]=true'
        )
        .then(response => {
          this.report = response.data
          this.reports = [this.report]
        })
        .catch(error => {
          this.$vs.notify({
            title: 'Error',
            text: '',
            color: 'danger',
            position: 'top-right'
          })
          console.error(error)
        })
        .finally(() => this.$vs.loading.close())
    }
  },
  async mounted() {
    await this.getReport()

    this.interval = this.weekInterval()

    this.filterModel = [
      {
        name: 'bots',
        component: 'BotNoVersionFilter',
        width: 6,
        props: {
          allowEmpty: false,
          startAll: true
        }
      },
      {
        name: 'dates',
        component: 'DateRangeFilter',
        width: 6,
        props: {
          value: this.interval
        }
      }
    ]
  }
}
</script>

<style>
h6 {
  margin-bottom: 1rem;
}
</style>
